import {Route, BrowserRouter, Routes,} from "react-router-dom";
import Maintenance from "../Pages/maintenance";


const Rotas = () => {
    return(
        
        <BrowserRouter>
            <Routes>
                <Route element = { <Maintenance/> } path="/" exact/>
            </Routes>
        </BrowserRouter>
        
    )
}



export default Rotas;