import { Button } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const Maintenance = () => {    
    
    return (
        <div className="container">
            <div className="header">
                <img className="logoHeader" src="./Images/sigma.png"/>   
            </div>
            <div className="content">
                <div className="left">
                    <h1 className="title">Página em construção!</h1>
                    <h2 className="subtitle">Nosso website estará pronto em breve! Fique de olho!</h2>
                    <hr className="line"/>
                    <h2 className="chamada">Precisa de um sistema personalizado?</h2>
                    
                    <h3 className="subtitle">Entre em contato conosco para desenvolvermos seu projeto, seja <strong>Website</strong>, <strong>Desktop</strong> ou <strong>App</strong>!</h3>
                    <Button href='https://wa.me/+5545991166770' sx={{backgroundColor:'#293D09', padding:2, color:'#FFF', marginTop:3}}><WhatsAppIcon sx={{marginRight:1}}/>Fazer Orçamento!</Button>
                </div>
                <img className="imgMaintenance" src='./Images/maintenance.png'></img>
            </div>            
        </div>
    )
}
export default Maintenance;